// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/home/kasper/dev/bytesandbrains.github.io/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/home/kasper/dev/bytesandbrains.github.io/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/home/kasper/dev/bytesandbrains.github.io/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cases-js": () => import("/home/kasper/dev/bytesandbrains.github.io/src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-contact-js": () => import("/home/kasper/dev/bytesandbrains.github.io/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("/home/kasper/dev/bytesandbrains.github.io/src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("/home/kasper/dev/bytesandbrains.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-what-we-do-js": () => import("/home/kasper/dev/bytesandbrains.github.io/src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-who-we-are-js": () => import("/home/kasper/dev/bytesandbrains.github.io/src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/kasper/dev/bytesandbrains.github.io/.cache/data.json")

