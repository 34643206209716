module.exports = [{
      plugin: require('/home/kasper/dev/bytesandbrains.github.io/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-115579130-1","head":false,"anonymize":true},
    },{
      plugin: require('/home/kasper/dev/bytesandbrains.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/home/kasper/dev/bytesandbrains.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
